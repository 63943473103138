<template>
  <layout-default-footer-container
    v-if="menu && menu.length"
    class="footer footer-mobile"
  >
    <div
      v-for="(link, i) in menu"
      :key="i"
      :class="{
        'mb2-8': link.key === 4 && !link.isExpanded,
        'mb2-4': link.key === 4 && link.isExpanded}
      "
      class="column link app-footer-mobile__column"
      data-testid="element_000221"
    >
      <template v-if="link.links.length">
        <div
          class="title-category"
          :class="!link.isExpanded && 'app-footer-mobile__title-category'"
          data-testid="element_000222"
          @click="changeCategoryVisible(i)"
        >
          <span data-testid="element_000363"> {{ link.name }} </span>
          <svg
            :class="categoryArrowClasses(link.isExpanded)"
            class="app-footer-mobile__svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href="/svg/arrow-down.svg#arrow-down" />
          </svg>
        </div>
        <collapse :when="link.isExpanded" class="v-collapse">
          <div class="links" data-testid="element_000223">
            <template v-for="linkChild in link.links" :key="linkChild.id">
              <div
                v-if="applyRule(linkChild)"
                class="column link"
                data-testid="element_000224"
              >
                <ui-link :link="{ to: linkChild.link }">
                  {{ linkChild.label }}
                  <span v-if="linkChild.subtitle" class="subtitle-category" data-testid="element_000225">
                    {{ linkChild.subtitle }}
                  </span>
                </ui-link>
              </div>
            </template>
          </div>
        </collapse>
      </template>
    </div>

    <layout-default-footer-connect-with-us />
  </layout-default-footer-container>
</template>

<script lang="ts" setup>
import { Collapse } from "vue-collapsed"

const {
  menu,
  categoryArrowClasses,
  changeCategoryVisible,
} = useFooterMenu()

const { applyRule } = useCanDisplayItem()
</script>

<style lang="scss">
.app-footer-mobile__column {
  &:nth-child(2) {
    @include bp-mobile() {
      padding-top: 0;
    }
  }
  &:not(:nth-child(2)) {
    @include bp-mobile() {
      margin-top: 8rem;
    }
  }
}
.app-footer-mobile__title-category {
  margin-bottom: 0 !important;
}
.app-footer-mobile__svg {
  max-width: 22rem;
}
</style>
